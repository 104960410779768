import React from "react"
import Section from "../components/Section/Section"
import Layout from "../components/Layout/Layout"
import HubspotForm from "react-hubspot-form"
import "../styles/contact.scss"
import Seo from "../components/Seo/Seo"

const Contact = () => {
  return (
    <Layout hideForm>
      <Seo title="Contact | Resistant.AI" />

    <Section sectionClass="Contact__body">

        <div className="w-wrap">
          <div className="w1-2">

          <div className="MainSection__caption">Contact</div>
            <h1 className="MainSection__title">Get<span className="highlighted"> Started</span></h1>
            <div className="mt-3 mb-8 justified">
              <p className="font-24">Complete the form to get in touch or reach out directly. We would love to hear from you.</p>
            </div>
          </div>


          <div className="w1-2 Contact__form">
            <HubspotForm
            portalId="5669546"
            formId="1f0a53f1-6e3a-4086-a1df-b3c0867257d6"
            region="na1"
            />
          </div>

        </div>

      </Section>

      <Section sectionClass="Contact__info">

      <div className="w-wrap">
        <h2 className="mb-8">Resistant AI <span className="highlighted">Contact Information</span></h2>
      </div>

      <div className="w-wrap">

        <div className="Contact__tiles__item w1-4">
          <h3>Prague</h3>
          <p>Lazarská 13/8</p>
          <p>120 00 Nové Město, Prague</p>
          <p>Czech Republic</p>
          <p>+420 721 643 797</p>
        </div>

        <div className="Contact__tiles__item w1-4">
          <h3>London</h3>
          <p>31-35 Kirby Street</p>
          <p>London, EC1N 8TE</p>
          <p>United Kingdom</p>
        </div>

        <div className="Contact__tiles__item w1-4">
          <h3>Brussels</h3>
          <p>Belgium</p>
        </div>

        <div className="Contact__tiles__item w1-4">
          <h3>New York</h3>
          <p>67 West St.</p>
          <p>Brooklyn, NY 11222</p>
          <p>USA</p>
        </div>

      </div>

      <div className="Contact__tiles__email__wrapper"><p className="Contact__tiles__email">Contact email: <a>sales@resistant.ai</a></p></div>

      </Section>

    </Layout>
  )
}

export default Contact
